var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      ref: "formUpdateLesson",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitUpdate.apply(null, arguments)
        },
      },
    },
    [
      _c("b-alert", { attrs: { variant: "danger", show: _vm.error } }, [
        _c("h4", { staticClass: "alert-heading" }, [
          _vm._v("Ops, nenhuma informação encontrada."),
        ]),
        _c("div", { staticClass: "alert-body" }, [
          _vm._v(
            " Não foi possível encontrar as informações através do UUID, volte para a listagem e tente novamente. "
          ),
          _c("br"),
          _c("p", { staticClass: "mt-1" }, [
            _vm._v(" UUID: "),
            _c("strong", [_vm._v(_vm._s(this.$route.params.uuid))]),
          ]),
        ]),
      ]),
      !_vm.loading && !_vm.error
        ? _c(
            "div",
            { staticClass: "form-row" },
            [
              _c(
                "b-col",
                { attrs: { md: "8" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Informações básicas" },
                    },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-row",
                            { staticClass: "form-row" },
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _c("i", {
                                          staticClass:
                                            "text-danger bi bi-record-circle",
                                        }),
                                        _vm._v(" Título "),
                                      ]),
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.item.title.$error,
                                        },
                                        attrs: { placeholder: "Título" },
                                        model: {
                                          value: _vm.item.title,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "title", $$v)
                                          },
                                          expression: "item.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "6" } },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticStyle: { display: "flow-root" },
                                      attrs: { for: "" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "float-left" },
                                        [_vm._v("Subtítulo")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-form-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: { placeholder: "Subtítulo" },
                                        model: {
                                          value: _vm.item.subtitle,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.item, "subtitle", $$v)
                                          },
                                          expression: "item.subtitle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { attrs: { header: "Descrição" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("quill-editor", {
                                attrs: {
                                  options: _vm.snowOption,
                                  "auto-height": true,
                                },
                                model: {
                                  value: _vm.item.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "description", $$v)
                                  },
                                  expression: "item.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Professor" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            { staticClass: "mb-0" },
                            [
                              _c(
                                "v-select",
                                {
                                  ref: "selectTeacher",
                                  class: {
                                    "is-invalid": _vm.$v.item.teacher.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o nome do professor",
                                    options: _vm.optionsTeachers,
                                  },
                                  on: { search: _vm.fetchTeachersOptions },
                                  model: {
                                    value: _vm.item.teacher,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "teacher", $$v)
                                    },
                                    expression: "item.teacher",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectTeacher.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-1",
                      attrs: { header: "Vídeo e Streaming" },
                    },
                    [
                      _c("b-card-text", [
                        _c(
                          "div",
                          { staticClass: "form-row" },
                          [
                            _vm.item.time && _vm.item.streaming
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 pb-2" },
                                  [
                                    _vm.item.streaming.title === "YouTube"
                                      ? _c("b-embed", {
                                          attrs: {
                                            type: "iframe",
                                            aspect: "16by9",
                                            src:
                                              "https://www.youtube.com/embed/" +
                                              _vm.item.code +
                                              "?rel=0",
                                            allowfullscreen: "",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Streaming "),
                                ]),
                                _c(
                                  "b-form-group",
                                  [
                                    _c("v-select", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.streaming.$error,
                                      },
                                      attrs: {
                                        label: "title",
                                        placeholder: "Selecione o streaming",
                                        required: "",
                                        searchable: false,
                                        "item-text": "title",
                                        "item-value": "code",
                                        options: _vm.optionsStreamings,
                                      },
                                      model: {
                                        value: _vm.item.streaming,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "streaming", $$v)
                                        },
                                        expression: "item.streaming",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "7" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Código do vídeo "),
                                ]),
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0 mb-m-1" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid": _vm.$v.item.code.$error,
                                      },
                                      model: {
                                        value: _vm.item.code,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "code", $$v)
                                        },
                                        expression: "item.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "5" } },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Tempo "),
                                ]),
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: ["##:##:##"],
                                          expression: "['##:##:##']",
                                        },
                                      ],
                                      class: {
                                        "is-invalid": _vm.$v.item.time.$error,
                                      },
                                      attrs: {
                                        placeholder: "HH:MM:SS",
                                        type: "tel",
                                      },
                                      model: {
                                        value: _vm.item.time,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "time", $$v)
                                        },
                                        expression: "item.time",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Material" } },
                    [
                      _c("b-card-text", [
                        _c(
                          "div",
                          { staticClass: "form-row" },
                          [
                            _c("b-col", { attrs: { md: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "form-group mb-0" },
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectMaterial",
                                      attrs: {
                                        multiple: "",
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder:
                                          "Digite o título do material",
                                        options: _vm.materialsOptions,
                                      },
                                      on: { search: _vm.fetchMaterialsOptions },
                                      model: {
                                        value: _vm.item.materials,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "materials", $$v)
                                        },
                                        expression: "item.materials",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectMaterial.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { header: "Tags" } },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Tag Tema "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectTema",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.tags.subject.$error,
                                  },
                                  attrs: {
                                    multiple: "",
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título da tag",
                                    options: _vm.optionsTagTema,
                                  },
                                  on: { search: _vm.fetchTagsCategoryTema },
                                  model: {
                                    value: _vm.item.tags.subject,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item.tags, "subject", $$v)
                                    },
                                    expression: "item.tags.subject",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectTema.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Tag Concurso "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectContest",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.tags.contest.$error,
                                  },
                                  attrs: {
                                    multiple: "",
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título da tag",
                                    options: _vm.optionsTagConcurso,
                                  },
                                  on: { search: _vm.fetchTagsCategoryConcurso },
                                  model: {
                                    value: _vm.item.tags.contest,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item.tags, "contest", $$v)
                                    },
                                    expression: "item.tags.contest",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectContest.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            { staticClass: "mb-0" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Tag Linha do tempo "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectTimeline",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.tags.timeline.$error,
                                  },
                                  attrs: {
                                    multiple: "",
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título da tag",
                                    options: _vm.optionsTagLinhaDoTempo,
                                  },
                                  on: {
                                    search: _vm.fetchTagsCategoryLinhaDoTempo,
                                  },
                                  model: {
                                    value: _vm.item.tags.timeline,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item.tags, "timeline", $$v)
                                    },
                                    expression: "item.tags.timeline",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectTimeline.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("ButtonsActionsFooter", {
                    attrs: {
                      routerBack: "lessons-list",
                      variant: "success",
                      submited: _vm.submited,
                      text: "Salvar",
                      subtext: "Aguarde...",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }